
import { reactive } from "vue";

import EventEmitter from 'o365.modules.EventEmitter.ts';

import Distinct from "mobile.controls.filter.fields.Distinct.js";
import Checkbox from "mobile.controls.filter.fields.Checkbox.js";
import Range from "mobile.controls.filter.fields.Range.js";
import Slider from "mobile.controls.filter.fields.Slider.js";

const fieldTypes = {
    "Distinct": Distinct,
    "Checkbox": Checkbox,
    "Range": Range,
    "Slider": Slider,
};

const filters = {};

export default class Filter {
    constructor(options) {
        Object.assign(this, options);

        this.storageKey = `MobileFilter:${this.id}`;
        this.loadStorage();

        this.search = "";

        this.field = null;
        this.order = null;

        this.showFieldSheet = false;
        this.showOrderSheet = false;

        this.eventHandler = new EventEmitter();

        filters[this.id] = this;
    }

    loadStorage() {
        try { this.storage = JSON.parse(localStorage.getItem(this.storageKey)) } catch (e) {}

        // validate storage
        if (this.storage == null || typeof this.storage !== "object") {
            this.storage = {};
        }
        if (this.storage.fields == null || typeof this.storage.fields !== "object") {
            this.storage.fields = {};
        }
        if (!Array.isArray(this.storage.sorts)) {
            this.storage.sorts = [];
        }
        if (!this.orders[this.storage.order]) {
            this.storage.order = 0;
        }

        // create fields
        this.fields = this.fields.map(e => new fieldTypes[e.type]({ ...e, filter: this }));

        // get order
        this.order = this.orders[this.storage.order];

        this.saveStorage();
    }

    saveStorage() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.storage));
    }

    selectField(field) {
        this.field = field;
        if (field) {
            this.showFieldSheet = true;
        }
    }

    clearField() {
        if (this.field) {
            this.field.clear();
        }
        this.selectField(null);
        this.showFieldSheet = false;
    }

    selectOrder(order) {
        this.storage.order = order;
        this.saveStorage();
    }

    getSortOrder() {
        return this.orders[this.storage.order]?.order || [];
    }

    getSearchClause() {
        if (!this.search) {
            return;
        }

        let clauses = [];

        for (let name of this.searchFields) {
            clauses.push(`[${name}] LIKE '%${this.search}%'`);
        }

        return clauses.map(e => `(${e})`).join(" OR ");
    }

    applyToFilterObject(filterObject) {
        filterObject ??= this.dataObject?.filterObject;

        if (filterObject === undefined || filterObject === null) {
            console.warn('No filter object or data object provided');
            return;
        }

        let items = [];

        for (let field of this.fields) {
            const newItems = field.getFilterItems();

            if (newItems) {
                items = items.concat(newItems);
            }
        }

        const search = this.search.trim().split(/\s+/g).filter((searchValue) => searchValue);

        for (const name of this.searchFields) {
            for (const searchValue of search) {
                items.push({
                    column: name,
                    operator: 'contains',
                    value: searchValue,
                });
            }
        }

        filterObject.clear();
        filterObject.updateItems({ items: items });
        filterObject.apply();
    }

    getFilterString(exceptField) {
        let clauses = [];

        for (let field of this.fields) {
            if (field !== exceptField) {
                const clause = field.getFilterString();
                if (clause) {
                    clauses.push(clause);
                }
            }
        }

        const searchClause = this.getSearchClause();
        if (searchClause) {
            clauses.push(searchClause);
        }

        return clauses.map(e => `(${e})`).join(" AND ");
    }

    update() {
        this.saveStorage();
        this.eventHandler.emit("update");
    }

    on(event, listener) {
        return this.eventHandler.on(event, listener);
    }

    off(event, listener) {
        return this.eventHandler.off(event, listener);
    }
}

export function getOrCreateFilter(options) {
    if (!filters[options.id]) {
        new Filter(options);
    }
    return reactive(filters[options.id]);
}
